import {PublicClientApplication, Configuration, PopupRequest, SilentRequest, AuthenticationResult} from '@azure/msal-browser';
import {envConfig} from '../env';

async function getMsalConfig(): Promise<Configuration> {
	return {
		auth: {
			clientId: await envConfig.get('CLIENT_ID'),
			authority: `https://login.microsoftonline.com/6b366179-8a3e-4f1b-a30f-272097b5aa57`,
			redirectUri: `${window.location.origin}/`,
		},
		cache: {
			cacheLocation: 'localStorage',
			storeAuthStateInCookie: true,
		},
	};
}

async function handlePublicClientApplication(): Promise<PublicClientApplication> {
	return new PublicClientApplication(await getMsalConfig());
}

let msalInstance: Promise<PublicClientApplication> | undefined;
export function getMsalInstance(): Promise<PublicClientApplication> {
	if (!msalInstance) {
		msalInstance = handlePublicClientApplication();
	}
	return msalInstance;
}

export const loginScope: SilentRequest & PopupRequest = {
	scopes: ['openid', 'profile', 'email', 'User.Read'],
	extraScopesToConsent: ['api://52003e80-b011-42ba-951d-09665b00a719/Access'],
};

export const backendRequest = (forceRefresh = false): SilentRequest & PopupRequest => {
	return {
		scopes: ['api://52003e80-b011-42ba-951d-09665b00a719/Access'],
		forceRefresh,
	};
};

let authPending: any | undefined;
let authPendingPromise: Promise<void> | undefined;
export const getAuthReponse = async (request: PopupRequest | SilentRequest): Promise<AuthenticationResult> => {
	if (!authPendingPromise) {
		authPendingPromise = new Promise((resolve) => (authPending = resolve));
	} else {
		await authPendingPromise;
	}
	const msalInstance = await getMsalInstance();
	let response: AuthenticationResult | undefined;
	try {
		response = await msalInstance.acquireTokenSilent(request);
	} catch (err) {
		response = await msalInstance.acquireTokenPopup(request);
	}
	// resolve pendings
	if (authPending) {
		authPending();
		authPending = undefined;
		authPendingPromise = undefined;
	}
	if (!response) {
		throw new Error('no auth response');
	}
	return response;
};

export const getBackendAuthResponse = (): Promise<AuthenticationResult> => getAuthReponse(backendRequest());

export const refreshBackendAuthResponse = (): Promise<AuthenticationResult> => getAuthReponse(backendRequest(true));

export const getLoginAuthResponse = (): Promise<AuthenticationResult> => getAuthReponse(loginScope);
