import {setLogger, UrlParser, stringParser, reactEnv, ConfigMap} from '@avanio/variable-util';
import {base64Uint8ArrayParser} from './lib/uint8ArrayParser';
import {fetchEnv} from './lib/env/fetchUtils';

// if (process.env.NODE_ENV === 'development') {
setLogger(console);
// }

const loaders = [reactEnv(), fetchEnv()];

const urlParser = new UrlParser({urlSanitize: true});

type EnvConfig = {
	API_HOST: URL;
	CLIENT_ID: string;
	PUBLIC_VAPID_KEY: Uint8Array | undefined;
};

export const envConfig = new ConfigMap<EnvConfig>({
	API_HOST: {loaders, parser: urlParser, defaultValue: new URL('http://localhost:8964'), params: {showValue: true}},
	CLIENT_ID: {loaders, parser: stringParser(), defaultValue: '8a989a47-5786-415f-a001-a9972504cd2b', params: {showValue: true}},
	PUBLIC_VAPID_KEY: {loaders, parser: base64Uint8ArrayParser},
});

export const APP_NAME = 'Luolapeikko';
