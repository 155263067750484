/**
 * cacheMatch
 * @param req
 * @param options
 * @throws Error if no cache data and browser is offline
 */
export function getCacheOptions(data: (CacheQueryOptions & Record<string, any>) | undefined): CacheQueryOptions {
	const out: CacheQueryOptions = {};
	if (data) {
		if ('ignoreMethod' in data) {
			out.ignoreMethod = data.ignoreMethod;
		}
		if ('ignoreSearch' in data) {
			out.ignoreSearch = data.ignoreSearch;
		}
		if ('ignoreVary' in data) {
			out.ignoreVary = data.ignoreVary;
		}
	}
	return out;
}



export async function cleanCacheUrl(url: string, cacheName = 'default'): Promise<boolean> {
	if (typeof window !== 'undefined' && window.caches) {
		const cache = await window.caches.open(cacheName);
		return cache.delete(url);
	}
	return false;
}

export async function deleteCache(cacheName = 'default'): Promise<void> {
	if (typeof window !== 'undefined' && window.caches) {
		await window.caches.delete(cacheName);
	}
}

function wrapHeaders(hrd: Headers | undefined): Record<string, string> {
	const out: Record<string, string> = {};
	if (hrd) {
		hrd.forEach((e, k) => {
			out[k] = e;
		});
	}
	return out;
}
export interface IRequestLikeObject {
	url: Request['url'];
	method: Request['method'];
	headers: Record<string, string>;
	body: Request['body'];
}

export function toRequestObject(req: Request): IRequestLikeObject {
	const headers = new Headers(req.headers);
	headers.delete('Authorization');
	return {
		body: req.body,
		headers: wrapHeaders(headers),
		method: req.method,
		url: req.url,
	};
}

export function fromObjectToReqeust(data: IRequestLikeObject): Request {
	const { url, ...rest } = data;
	return new Request(url, rest);
}

export async function cacheGet(req: Request, options?: CacheQueryOptions, cacheName = 'default'): Promise<Response | undefined> {
	if (typeof window !== 'undefined' && window.caches) {
		const cache = await window.caches.open(cacheName);
		return cache.match(req, options);
	}
}

export async function getEtag(req: Request, options?: CacheQueryOptions, cacheName = 'default'): Promise<string | undefined> {
	return (await cacheGet(req, options, cacheName))?.headers.get('ETag') || undefined;
}

/**
 * modify request to add if-none-match header with etag from cache
 *
 * Note: both argument and return value are same patched request object
 */
export async function setIfNoneMatch(req: Request, options?: CacheQueryOptions, cacheName = 'default'): Promise<Request> {
	const etag = await getEtag(req, options, cacheName);
	if (etag) {
		req.headers.set('if-none-match', etag);
	}
	return req;
}

export function haveCache(): boolean {
	return typeof window !== 'undefined' && !!window.caches;
}

export async function deleteOldEntries(timestamp: Date, cacheName = 'default'): Promise<void> {
	if (typeof window !== 'undefined' && window.caches) {
		const cache = await window.caches.open(cacheName);
		const keys = await cache.keys();
		const old = keys.filter((res) => {
			const dateString = res.headers.get('date');
			if (!dateString) {
				return false;
			}
			return timestamp > new Date(dateString);
		});
		for (const req of old) {
			await cache.delete(req);
		}
	}
}
