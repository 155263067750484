import {FetchConfigLoader, IRequestCache} from '@avanio/variable-util';
import {cacheStore} from '@mharj/cache-storage';
import {isOnline} from '../browserUtils';
import {cacheGet, setIfNoneMatch} from '../commonCache';

/**
 * cache config for fetchEnv
 */
const requestCache: IRequestCache = {
	isOnline: isOnline,
	storeRequest: cacheStore,
	fetchRequest: cacheGet,
};

/**
 * configure the fetchEnv to load the settings.json file from public folder
 */
export const fetchEnv = new FetchConfigLoader(
	() => {
		return setIfNoneMatch(new Request('/config.json'));
	},
	{
		logger: process.env.NODE_ENV === 'development' ? console : undefined,
		cache: requestCache,
	},
).getLoader;
